import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var routers = [{
  path: '/h5-business-details',
  component: function component() {
    return import(/* webpackChunkName: "h5-business-details" */'@/views/h5-business-details/index.vue');
  },
  meta: {
    title: 'h5商机详情',
    keepAlive: false
  }
}, {
  path: '/h5-manager-customer-details',
  component: function component() {
    return import(/* webpackChunkName: "h5-manager-customer-details" */'@/views/h5/h5-manager-customer-details/index.vue');
  },
  meta: {
    title: 'h5团队经理客户详情',
    keepAlive: true
  }
}, {
  path: '/h5-one-key-pull-group',
  component: function component() {
    return import(/* webpackChunkName: "h5-one-key-pull-group" */'@/views/h5/one-key-pull-group/index.vue');
  },
  meta: {
    title: 'h5一键拉群',
    keepAlive: true
  }
}, {
  path: '/h5-choose-person',
  component: function component() {
    return import(/* webpackChunkName: "h5-choose-person" */'@/views/h5/one-key-pull-group/ChoosePerson.vue');
  },
  meta: {
    title: 'h5选择员工',
    keepAlive: false
  }
}, {
  path: '/h5-choose-customer',
  component: function component() {
    return import(/* webpackChunkName: "h5-choose-customer" */'@/views/h5/one-key-pull-group/ChooseCustomer.vue');
  },
  meta: {
    title: 'h5选择客户',
    keepAlive: false
  }
}, {
  path: '/h5-group-list',
  component: function component() {
    return import(/* webpackChunkName: "h5-group-list" */'@/views/h5/one-key-pull-group/GroupList.vue');
  },
  meta: {
    title: 'h5待加入群聊列表',
    keepAlive: false
  }
}, {
  path: '/h5-enter-group',
  component: function component() {
    return import(/* webpackChunkName: "h5-enter-group" */'@/views/h5/one-key-pull-group/EnterGroup.vue');
  },
  meta: {
    title: 'h5加入群聊',
    keepAlive: false
  }
}];
export default routers;