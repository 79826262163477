import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description:此文件用于放置消息通知页面弹窗
 * **/

export var noticeRouter = [{
  path: '/require-approve',
  component: function component() {
    return import('@/views/pcDialog/requireApprove/index.vue');
  },
  meta: {
    title: '需求审批',
    keepAlive: false
  }
}, {
  path: '/biz-allocation',
  component: function component() {
    return import('@/views/pcDialog/bizAllocation/index.vue');
  },
  meta: {
    title: '转介绍审批',
    keepAlive: false
  }
}];